<template>
  <div class="columns">
    <div class ="column">
      <div id="jvenn-container" style="width: 500px; height: 650px;"></div>
    </div>
    <div class ="column">
      <br />
      <br />
      <br />
      <br />
      <span id="search-status"></span>
      <b-field label="Display mode">
        <b-radio-button v-model="displayMode"
          native-value="classic"
          @input="render()"
          type="is-primary">
          Classic
        </b-radio-button>
        <b-radio-button v-model="displayMode"
          native-value="edwards"
          @input="render()"
          type="is-primary">
          Edwards
        </b-radio-button>
      </b-field>
      <b-field label="Find an element in list(s)">
        <b-input
          id="search-field"
          @input="render()"
          v-model="elementToFind"
        />
      </b-field>
      <hr />
      <b-input disabled v-model="clikedValues" type="textarea" />
    </div>
  </div>
</template>

<script>
let $ = window.$ = window.jQuery = require('jquery')

export default {
  name: 'Venn',
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      clikedValues: 'Select an intersection to visualize its elements.',
      displayMode: 'classic',
      elementToFind: null
    }
  },
  methods: {
    render: function () {
      let self = this
      $('#jvenn-container').jvenn({
        series: this.data.data.series,
        displayStat: true,
        displaySwitch: true,
        displayMode: self.displayMode,
        searchInput: $('#search-field'),
        searchStatus: $('#search-status'),
        searchMinSize: 1,
        fnClickCallback: function () {
          self.clikedValues = ''
          if (this.listnames.length === 1) {
            self.clikedValues += 'Elements only in '
          } else {
            self.clikedValues += 'Common elements in '
          }
          for (let nameVal in this.listnames) {
            self.clikedValues += this.listnames[nameVal] + ' '
          }
          self.clikedValues += ':\n'
          for (let val in this.list) {
            self.clikedValues += this.list[val] + '\n'
          }
        }
      })
    }
  },
  mounted () {
    const canvas2svg = document.createElement('script')
    canvas2svg.setAttribute(
      'src',
      'https://vm-asterics-prod.toulouse.inrae.fr/js/canvas2svg.js'
    )
    canvas2svg.async = true
    document.head.appendChild(canvas2svg)
    const jvenn = document.createElement('script')
    jvenn.setAttribute(
      'src',
      'https://vm-asterics-prod.toulouse.inrae.fr/js/jvenn.min.js'
    )
    jvenn.async = true
    document.head.appendChild(jvenn)
  }
}
</script>

<style>
  .textarea:not([rows]) {
    min-height: 18em;
  }
</style>
